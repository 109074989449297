<template>
  <div class="container">
    <PublicHeader :totalName="$t('with').title" />
    <div class="top">
      <div class="name">{{ $t('with').selectLin }}</div>
      <!-- 选择通道 -->
      <div class="tabs">
        <div class="item" :class="WalletNetworkType == 'TRC20' ? 'active' : ''" @click="WalletNetworkType = 'TRC20'">
          TRC20</div>
        <div class="item" :class="WalletNetworkType == 'ERC20' ? 'active' : ''" @click="WalletNetworkType = 'ERC20'">
          ERC20</div>
        <div class="item" :class="WalletNetworkType == 'OMNI' ? 'active' : ''" @click="WalletNetworkType = 'OMNI'">OMNI
        </div>
      </div>
    </div>
    <!-- 提币内容 -->
    <div class="content">
      <div class="name">{{ $t('with').coin }}：<span>USDT</span></div>
      <!-- 最小提币数量 -->
      <div class="num">{{ $t('with').amount }}：<span>{{ $t('with').mixAmount }}</span></div>
      <van-field v-model="Amount" :placeholder="$t('with').amountMsg"></van-field>
      <!-- 可提余额 -->
      <div class="tips">{{ $t('with').Balance }}：{{ userInfo.Balance }}USDT</div>
      <div class="tips" v-if="userInfo.Grade == 'Mv0' || userInfo.Grade == 'Mv1'">{{ $t('with').ratetips }}</div>
      <div class="tips" v-else>{{ $t('with').ratetipsNo }}</div>
      <div class="address">
        <span>{{ $t('with').address }}：</span>
        <div class="txt" @click="showSelect = true"><span>{{ Address }}</span><van-icon name="arrow" size="14"
            color="#999999" /></div>
      </div>
      <div class="msg">· {{ $t('with').tips1 }}<br>
        · {{ $t('with').tips2 }}<br>
        · {{ $t('with').tips3 }}</div>
    </div>
    <!-- 到账手续费 -->
    <div class="received">{{ $t('with').resdAmount }}：{{ AeceivedAmount }} USDT</div>
    <div class="fees" v-if="userInfo.Grade == 'Mv0' || userInfo.Grade == 'Mv1'">{{ $t('with').ratefee }}：{{ Rate }}
      USDT，{{ $t('with').rateMsg }}</div>
    <!-- 按钮 -->
    <van-button color="#C92B2E" block style="border-radius: 10px;height: 50px;margin-top: 10px;" @click="preToPass">{{
      $t('with').submit }}</van-button>
    <!-- 选择地址 -->
    <van-popup v-model="showSelect" closeable round :style="{ width: '320px', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('with').address }}</div>
        <div class="info">
          <div class="address">{{ Address }}</div>
          <div class="btns">
            <div class="btn" @click="showSelect = false; showEdit = true">{{ $t('with').edit }}</div>
            <div class="btn2" @click="showSelect = false">{{ $t('public').confirm }}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 修改地址 -->
    <van-popup v-model="showEdit" closeable round :style="{ width: '320px', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('with').edit }}{{ WalletNetworkType }}</div>
        <div class="info">
          <van-field v-model="Address" rows="4" type="textarea" :placeholder="$t('withAddress').addressMsg"></van-field>
          <div class="tip">* {{ $t('withAddress').tips }}</div>
          <div class="btns">
            <div class="btn" @click="showEdit = false">{{ $t('public').cancel }}</div>
            <div class="btn2" @click="EditAddress">{{ $t('public').confirm }}</div>
          </div>
        </div>
      </div>

    </van-popup>
    <!-- 输入密码 -->
    <van-popup v-model="showPassword" closeable round :style="{ width: '350px', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('public').Paypassword }}</div>
        <div class="password">
          <van-password-input :value="Password" :length="6" :gutter="5" :focused="showKeyboard"
            @focus="showKeyboard = true" />
        </div>
        <div class="pwstips">{{ $t('public').PaypasswordMsg }}</div>
        <div class="pwsbtn" @click="CheckFundPassword">{{ $t('public').confirm }}</div>
      </div>
      <!-- 数字键盘 -->
      <van-number-keyboard v-model="Password" :show="showKeyboard" @blur="showKeyboard = false" />
    </van-popup>

  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { getRandomString } from '@/utils/validate'
import PublicHeader from '@/components/publicHeader'
import { color } from 'echarts';

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      showSelect: false,
      showEdit: false,
      WalletNetworkType: 'TRC20',
      Address: '',
      Amount: '',
      AeceivedAmount: '',
      Rate: '',
      userInfo: {},
      Password: '',
      showKeyboard: false,
      showPassword: false,
      RatePrecent: 0,
    }
  },
  watch: {
    Amount() {
      this.Rate = this.Amount * this.RatePrecent
      this.AeceivedAmount = this.Amount - this.Rate
      // 余额校验
      if (this.Amount > this.userInfo.Balance)
        this.$toast(this.$t('with').BalanceNot)
    },
    WalletNetworkType() {
      this.onChangeType()
    },
    Password() {
      if (this.Password.length == 6)
        this.showKeyboard = false
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getAccountBase()
  },
  methods: {
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
      if (this.userInfo.Grade == 'Mv0' || this.userInfo.Grade == 'Mv1')
        this.RatePrecent = 0.002
      this.onChangeType()
    },
    //切换通道
    onChangeType() {
      if (this.WalletNetworkType == 'TRC20')
        this.Address = this.userInfo.TRC20Address
      if (this.WalletNetworkType == 'ERC20')
        this.Address = this.userInfo.ERC20Address
      if (this.WalletNetworkType == 'OMNI')
        this.Address = this.userInfo.OMNIAddress
    },
    // 修改提币地址
    async EditAddress() {
      if (!this.Address) {
        this.$toast({ message: this.$t('withAddress').addressMsg })
        return false
      }
      const form = {
        WalletNetworkType: this.WalletNetworkType,
        Address: this.Address,
      }
      await userApi.UpdateWalletAddress(form)
      this.showEdit = false
      this.getAccountBase()
      this.$toast({ message: this.$t('withAddress').succss })
    },
    //交易密码预检
    preToPass() {
      if (!this.Amount) {
        this.$toast({ message: this.$t('with').amountMsg })
        return false
      }
      // 最小提现金额
      if (this.Amount < 10) {
        this.$toast(this.$t('with').mixAmount)
        return false
      }
      // 余额校验
      if (this.Amount > this.userInfo.Balance) {
        this.$toast(this.$t('with').BalanceNot)
        return false
      }
      // 提币地址校验
      if (!this.Address) {
        this.$dialog.alert({
          title: this.$t('withAddress').setTips,
          confirmButtonText: this.$t('public').confirm,
        })
          .then(() => {
            this.$router.push({ name: 'WithAddress' })
          })
        return false
      }
      //是否设置交易密码
      if (!this.userInfo.BindFundPassword) {
        this.$dialog.alert({
          title: this.$t('public').noPayPasswordMsg,
          confirmButtonText: this.$t('public').confirm,
        })
          .then(() => {
            this.$router.push({ name: 'SetPayPwd' })
          })
        return false
      }
      this.showPassword = true
      this.showKeyboard = true
    },
    //校验密码
    async CheckFundPassword() {
      if (this.Password.length < 6) {
        this.$toast(this.$t('public').PaypasswordMsg)
        return
      }
      this.Password = this.Password.slice(0, 6)
      await userApi.CheckFundPassword({ Str: this.Password })
      this.handleSub()
    },
    // 提交
    async handleSub() {
      const form = {
        Amount: this.Amount,
        WalletNetworkType: this.WalletNetworkType,
        Address: this.Address,
      }
      await userApi.Withdrawal(form)
      this.$toast({ message: this.$t('with').subMsg })
      this.$router.go(-1)
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('public').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.value = text
        textarea.select()
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('public').copySuccess)
        return resolve(true)
      })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 65px 15px 0 15px;
  background: #F6F6F6;

  .top {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px;

    .name {
      font-weight: bold;
      font-size: 16px;
    }

    .tabs {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item {
        width: 99px;
        height: 47px;
        background: #FFFFFF;
        border: 1px solid #CFCFCF;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: #333333;
      }

      .active {
        font-weight: bold;
        color: #FFFFFF;
        border: none;
        background: #C92B2E;
        box-shadow: 0 2px 7px 1px rgba(195, 8, 12, 0.42);
      }
    }

  }

  .content {
    margin-top: 10px;
    padding: 15px;
    background: #FFFFFF;
    border-radius: 5px;

    .name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
    }

    .num {
      margin: 15px 0 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;

      span {
        font-weight: 500;
        font-size: 13px;
        color: #999999;
      }
    }

    .tips {
      margin-top: 10px;
      font-size: 12px;
      color: #999999;
    }

    .address {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }

      .txt {
        display: flex;
        align-items: center;
        text-align: right;

        span {
          margin-right: 5px;
          width: 130px;
          font-weight: bold;
          font-size: 14px;
          color: #3485FF;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }

    .msg {
      margin-top: 10px;
      font-size: 12px;
      color: #999999;
      line-height: 17px;
    }

    .van-cell {
      background: #F2F2F2;
      height: 50px;
      padding: 10px;
      border-radius: 5px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .received {
    margin-top: 80px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }

  .fees {
    margin-top: 15px;
    text-align: center;
    font-size: 13px;
    color: #333333;
  }

  .popInfo {
    .van-cell {
      background: #F2F2F2;
      padding: 10px;
      border-radius: 5px;
      font-size: 15px;
    }

    ::v-deep .van-password-input__security {
      height: 49px;
    }

    ::v-deep .van-password-input__security li {
      background: #E8E8E8;
      border-radius: 5px;
    }

    .title {
      padding: 20px 0;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      border-bottom: 1px solid #DCDCDC;
    }

    .password {
      margin-top: 80px;
    }

    .pwstips {
      margin-top: 15px;
      text-align: center;
      font-size: 14px;
      color: #999999;
    }

    .pwsbtn {
      margin: 60px 90px 150px 90px;
      font-size: 15px;
      color: #FFFFFF;
      height: 40px;
      background: #C92B2E;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .info {
      padding: 15px 13px 20px 13px;
      text-align: center;

      .address {
        margin: 50px 0 60px 0;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        line-height: 25px;
        overflow-wrap: break-word;
      }

      .tip {
        padding: 10px 0 0 0;
        font-size: 14px;
        color: #FF9B37;
      }

      .btns {
        margin: 20px 13px 0 13px;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 119px;
          height: 40px;
          background: rgba(201, 43, 46, 0);
          border: 1px solid #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #C92B2E;
        }

        .btn2 {
          width: 119px;
          height: 40px;
          background: #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #fff;
        }
      }
    }
  }
}

::v-deep .van-popup__close-icon {
  color: #000000;
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}
</style>
